<template>
<pessoa-aba>
  <div class="m-b" :class="[{'m-t-n wrapper': !noPad}]">
    <div v-if="table.loading" class="flex items-center justify-center">
      <sl-loading content="" class="size-18 m-r-sm" /> Carregando histórico de comunicação com {{firstname(pessoa.name)}}
    </div>
    <div v-else>
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          :class="!tableClass ? 'erp-table erp-table-odd' : tableClass"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr @dblclick.native="open(props.row.id)" slot="body" slot-scope="props" :props="props" class="cursor-pointer">
          <u-td style="max-width: 70px; min-width: 70px; width: 70px" key="id" :props="props">
            {{ String(props.row.id).padStart(6, '0') }}
          </u-td>
          <u-td style="max-width: 110px; min-width: 110px; width: 110px" key="dateRegistry" :props="props">
            {{props.row.dateRegistry|formatDate}}
          </u-td>
          <u-td style="max-width: 110px; min-width: 110px; width: 110px" key="dateReaded" :props="props">
            <span v-if="props.row.dateReaded" class="text-green-10">{{props.row.dateReaded|formatDate}}</span>
            <span v-else>-</span>
          </u-td>
          <u-td class="td-limit l-200" key="subject" :props="props">
            {{props.row.subject}}
          </u-td>
          <u-td key="recipient" :props="props">
            {{props.row.recipient}}
          </u-td>
          <u-td key="type" :props="props">
            {{props.row.type}}
          </u-td>
          <u-td class="text-right" key="options" :props="props">
            <div class="cursor-pointer">
              <div class="text-right no-select"><i class="fa fa-chevron-down text-grey-5 font-10" /></div>
              <u-popover class="window-context-menu menu-profile min" anchor="bottom right" self="top right">
                <ul>
                  <menu-options-item close label="Consultar" @click="open(props.row.id)" />
                </ul>
              </u-popover>
            </div>
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</pessoa-aba>
</template>

<script>
import PessoaAba from "@/components/pessoa/components/include/pessoa/apps/Aba"
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import SlLoading from "components/layout/components/Loading.vue"
import {historicoComunicacao} from "@/domain/pessoa/domain"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import tableColumnsCache from "@/utils/tableColumnsCache"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import {datePtToEn} from "@/utils/date"
import {date, UPopover, UTable, UTd, UTh, UTr} from "uloc-vue"
import comunicacaoWindow from "components/pessoa/windows/comunicacaoWindow"

const listName = 'table.pessoa.comunicacao'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Envio', name: 'dateRegistry', active: true, sortable: true, ordering: 1},
  {label: 'Leitura', name: 'dateReaded', active: true, sortable: true, ordering: 1},
  {label: 'Assunto', name: 'subject', active: true, sortable: true, ordering: 1},
  {label: 'Destinatário', name: 'recipient', active: true, sortable: true, ordering: 1},
  {label: 'Canal', name: 'type', active: true, sortable: true, ordering: 1},
], 2)

const filters = {
  id: null,
  search: null
}
export default {
  name: "PessoaComunicacao",
  props: {
    pessoaEntity: {
      required: false
    },
    email: {
      required: false
    },
    referEntity: {
      required: false
    },
    referEntityId: {
      required: false
    },
    noPad: {
      required: false,
      type: Boolean,
      default: false
    },
    tableClass: {
      required: false
    }
  },
  components: {MenuOptionsItem, SlLoading, PessoaAba,
    //ErpInput,
    UTable, UTr, UTh, UTd, UPopover},
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    return {
      busca: null,
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 50
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    pessoa () {
      return this.pessoaEntity || this.parseProps.pessoa
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.pessoa && extraFilters.push(`&pessoa=${this.pessoa.id}`)
      this.referEntity && extraFilters.push(`&referEntity=${this.referEntity}`)
      this.referEntityId && extraFilters.push(`&referEntityId=${this.referEntityId}`)
      this.email && extraFilters.push(`&email=${this.email}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dateRegistry'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      historicoComunicacao(pagination.rowsPerPage, pagination.page, filtros, true)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    comunicacaoWindow,
    open (id) {
      this.comunicacaoWindow(id)
    }
  },
  mixins: [AppMixin]
}
</script>
